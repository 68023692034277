//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IndexPage'
}
